import React, { useState } from "react";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
import "./centralHospitalPatientCard.css";
import { statusCategories } from "../../helpers/utilities";

export const CentralHospitalPatientCard = ({item = [], handlePatientCard = ()=>{}}) => {

  const formattedDate = moment(item.appointmentDate).format("DD MMM, hh:mm A");
  const selectedColorObj = statusCategories.filter((obj) =>
    obj.statuses.includes(item.patientJourneyTag)
  );
  return (
        <div className="patientCardParentContainer" onClick={()=>handlePatientCard(item.appId,item.patientName )} key={item.appId}>
        <div className="patientCardHeader">
          <div className="patientCardHeaderLeftOuterContainer">
            <div className="patientCardHeaderLeftContainer">
              <Avatar className="patientCardHeaderAvatar">
                <PersonIcon sx={{ fontSize: "12px", color: "white" }} />
              </Avatar>
              <span className="patientCardHeaderName">
                {item.patientName}
              </span>
            </div>
            {item.patientJourneyTag ? <p className="patientCardHeaderJourneyField" style={{backgroundColor:`${selectedColorObj && selectedColorObj[0] ? selectedColorObj[0].color : ''}`,border:`0.1px solid ${selectedColorObj && selectedColorObj[0]  ? selectedColorObj[0].color : ''}`}}>{item.patientJourneyTag}</p>: ''}
          </div>
          <div className="patientCardHeaderPendingField" style={{backgroundColor:(item.pendingCount || 0) === 0?'#009817':'#DB3A3A'}}>Pending Fields : {item.pendingCount || 0}</div>
        </div>
        <div className="patientCardBody">
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">IPD Date</span>
            <span className="textReadOnlyValue" style={{color:'#3476CD'}}>{formattedDate || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">Surgery Name</span>
            <span className="textReadOnlyValue">{item.disease || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">Doctor Name</span>
            <span className="textReadOnlyValue">{item.doctorName || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">Provisional</span>
            <span className="textReadOnlyValue">{item.isProvisional || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">MOP</span>
            <span className="textReadOnlyValue paymentValueStyle">{item.finalModeOfPayment || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">Pre-Auth Status</span>
            <span className="textReadOnlyValue">{item.preAuthStatus || '--'}</span>
          </div>
          <div className="textReadOnlyRow">
            <span className="textReadOnlyLabel">Surgery Call</span>
            <span className="textReadOnlyValue">{item.surgeryStatus || '--'}</span>
          </div>
        </div>
      </div>
  );
};
