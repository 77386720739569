import { centralCommonDataConstants } from "../../_constants";
import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { getCentralFinalBillStatus } from "../../_services";
import { getValueAfterConvertStringToObjectKeys } from "../../central/helpers/utilities";

export function centralCommonData(state = centralGetInitialState("centralCommonData"), action) {
  switch (action.type) {
    case centralCommonDataConstants.FETCH_COMMON_DATA:
      return {
        ...state,
      };

    case centralCommonDataConstants.CENTRAL_COMMON_DATA_FETCHED:
      const { commonData } = action;
      return {
        ...state,
        commonData,
      };
    case centralCommonDataConstants.COMMON_DATA_FAILED:
      return {
        ...state,
        centralCommonDataFetchingError: action.error,
      };

    case centralCommonDataConstants.UPDATE_COMMON_DATA:
      const {payload:{reportingData, disable}}   = action
      let commonDataJson = {...state?.commonData}
      const data =  commonDataJson?.result?.data || []

      for (let item in data) {
        let pendingFields = 0
        data[item] && data[item]?.details?.forEach(element=>{
              let value = getValueAfterConvertStringToObjectKeys(reportingData, element.getKey)
              
              if(data[item].title==='Appointment Details' && element.getKey==='payment_mode'){
                if ((!value || value?.toLowerCase() !== 'cash')) {
                const admissionData = data[1].details
                admissionData.forEach(item=>{
                  if(item.key ==='surgeryStatusIns'){
                    item.isHideField = true
                    item.functionType = 'text-read-only'
                  }
                })}
                 else {
                  const admissionData = data[1].details
                admissionData.forEach(item=>{
                  if(item.key ==='surgeryStatusIns'){
                    item.isHideField = false
                    item.functionType = 'input-dropDown'
                  }
                })
                }
              }
              if(element.key ==="reasonForExtraChargeAtDischarge" || element.key === 'extraChargeReasons'){
               
                if(value && value?.length){
                  value = value.filter(item=>item.value).map(item=>item.value)
                }
                else{
                  value = []
                }    
              }
              if (element.getKey === "insuranceRoomType.value" && !value) {
                 value = getValueAfterConvertStringToObjectKeys(reportingData, "appQuotes.roomRent")
              }
              if (element.key === "fitForDischarge") {
                 value = value ? 'Yes' : 'Pending'
              }
              if (element.key === "tentativeAmount" && reportingData?.quotesArr) {
                value  = reportingData?.quotesArr[reportingData.quotesArr.length - 1]?.TentativeAmount || ''
              }
              if (element.key === "finalBillStatus") {
                const params =reportingData?.appointment_id || reportingData?._id
                getCentralFinalBillStatus(params)
                .then(res=>{
                  if(res.message==='Success' && typeof res?.data === 'object' && res?.data !== null && !Array.isArray(res?.data) ){
                    const data = res?.data
                    value = data?.status || ''
                    element.value = data?.status || ''
                  }
                })
                .catch(err=>{
                  console.log('Unable to get final bill status', err);
                  
                })
              }
              if (element.key === "preAuth_status") {
                if(value && value?.toLowerCase() === 'preauth sent'){
                  element.functionType = 'input-dropDown'
                }
                else{
                  element.functionType = 'text-read-only'
                }
              }
              
              if (value && (!Array.isArray(value) || value.length)) {
                element.value = value
                // element.disable = element?.forceDisable ? false : !!value
                element.disable =  false
              } else {
                if (element.functionType.includes('input') || element.functionType.includes('multi-select-dropDown')) {
                  element.value = value
                  element.disable =  false
                  pendingFields++;
                } else if (element.functionType.includes('upload-file')) {
                  element.value = value
                }
              }
            })
        data[item].pendingFields = pendingFields
      }
      if (commonDataJson.result) { 
            commonDataJson.result.fetched = true
      }
      return {
        ...state,
        ...commonDataJson,
      };
      case centralCommonDataConstants.CLEAR_CENTRAL_COMMON_DATA:
        let commonDataJson1 = {...state?.commonData}
        if (commonDataJson1.result) { 
          commonDataJson1.result.fetched = false
        }
        return {
          ...state,
          ...commonDataJson1,
        };
      
    default:
      return state;
  }
}
