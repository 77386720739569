import React, { useEffect, useState } from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { getPatientJourney } from '../../../_services';
import { useSelector } from 'react-redux';
import "./PatientJourney.css"
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader';
import { useNavigate } from 'react-router-dom';

function PatientJourney() {
  const navigate = useNavigate()
  const [patientJourneyData, setPatientJourneyData] = useState([])
  let patientList = useSelector(state => state.centralPatients)
  let activeHospitalId = useSelector(state => state.centralHospitalData.activeHospitalId)

  useEffect(() => {
    // mcoBRqd2pq
    getPatientJourney(patientList?.activePatientId).then((res) => {
      if (res.data && res.data.length > 0) {
        setPatientJourneyData(res.data)
      }
      else {
        setPatientJourneyData([])
      }
    })
  }, [patientList?.activePatientId])

  function formatDateToIST(inputDate) {
    const date = new Date(inputDate);
    
    const ISTDate = new Date(date).toLocaleString('en-US', { 
      timeZone: 'Asia/Kolkata',
      hour12: true 
    });
  
    const ISTParsedDate = new Date(ISTDate);
    const day = ISTParsedDate.getDate();
    const month = ISTParsedDate.toLocaleString('en-us', { month: 'long' });
    const year = ISTParsedDate.getFullYear();
  
    let hours = ISTParsedDate.getHours();
    const minutes = ISTParsedDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
  
    return `${day} ${month}, ${hours}:${minutes} ${ampm}`;
  }
  
  const handleBackButton = () => {
    navigate(`/central/${activeHospitalId}/${patientList?.activePatientId}`)
  }

  return (
    <>
      <CentralCommonHeader title={'Patient Journey'} backIcon={true} backArrowFunction={handleBackButton} />
      {patientJourneyData && patientJourneyData.length > 0  ? <Timeline className='patientJourney_timeline'>
        {
          patientJourneyData && patientJourneyData.length > 0 &&
            patientJourneyData.map((item, index) => {
              return (
                <>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: index === 0 ? '#3476CD' : '#393939' }} variant="filled" />
                      {patientJourneyData[index + 1] ? <TimelineConnector /> : ''}
                    </TimelineSeparator>
                    <TimelineContent style={{ color: "#000000", fontWeight: '600', fontSize: '16px' }}>
                      <div className='patientJourney_timelineContent'>
                        <p className='patientJourney_eventName'>{item.eventName}</p>
                        <div className='patientJourney_eventDetails'>
                          <p style={{ color: '#7D7D7D' }}>{item.actionTakenByUser} , {item.role}</p>
                          <p style={{ color: '#3476CD' }}>{formatDateToIST(item._created_at)}</p>
                        </div>
                      </div>


                    </TimelineContent>
                  </TimelineItem>
                </>
              )
            })
            
        }
      </Timeline>
      :
      <div className='noDataAvailable'>
              <p style={{textAlign: "center"}}>No Event Created Till Now</p>
            </div>
}
    </>
  )
}

export default PatientJourney