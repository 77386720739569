import React, { useEffect, useState } from 'react';
import './centralFilters.css';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { centralCommonDataActions, centralHospitalActions, centralPatientActions } from '../../../_actions';
import { Button } from '@mui/material';
import { formatCommonHeaderDate } from '../../helpers/utilities';
import dayjs from 'dayjs';

function CentralFilters({ selectedDate }) {
  let commonData = useSelector(state => state.centralCommonData.commonData);
  const dispatch = useDispatch();
  const [surgeryTagChecked, setSurgeryTagChecked] = useState([]);
  const [paymentModesChecked, setPaymentModesChecked] = useState([]);
  const [surgeryCallChecked, setSurgeryCallChecked] = useState([]);
  const [surgeryTagValues, setSurgeryTagValues] = useState([]);
  const [paymentModesValues, setPaymentModesValues] = useState([]);
  const [surgeryCallValues, setSurgeryCallValues] = useState([]);
  const [filterCount, setFilterCount] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [applyFilter, setApplyFilter] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null); // New state to manage expanded accordion
  let activeHospitalId = useSelector(state => state.centralHospitalData.activeHospitalId);

  useEffect(() => {
    dispatch(centralCommonDataActions.getCentralCommonData());
    return () => dispatch(centralCommonDataActions.clearCentralCommonData());
  }, []);

  useEffect(() => {
    if (commonData && commonData?.result?.decentralizeFilters) {
      setFilterData(commonData?.result?.decentralizeFilters);
      commonData?.result?.decentralizeFilters.forEach((item) => {
        let type = convertFilterHeaderToType(item.filterHeader);
        let tempArray = new Array(item?.filterOptions.length || 0).fill(false);
        if (type === 'statustag') {
          setSurgeryTagChecked(tempArray);
        }
        if (type === 'paymentmode') {
          setPaymentModesChecked(tempArray);
        }
        if (type === 'surgerycall') {
          setSurgeryCallChecked(tempArray);
        }
      });
    }
  }, [commonData]);

  useEffect(() => {
    const parts = window.location.pathname.split('/');
    let endDate = selectedDate!=null ? formatCommonHeaderDate(dayjs(selectedDate).add(24,'hours')) : null;
    let startDate = selectedDate !== null ? formatCommonHeaderDate(selectedDate) : null;
    let hospitalId = !activeHospitalId ? parts[2] : activeHospitalId;
    dispatch(centralPatientActions.getCentralPatientList({
      hospitalId,
      startDate,
      endDate,
      paymentModesValues,
      surgeryTagValues,
      surgeryCallValues
    }));

    if (window.location.search.includes("accessToken")) {
      dispatch(centralHospitalActions.getCentralHospitalList());
    }

    return () => dispatch(centralPatientActions.clearCentralPatientList());
  }, [selectedDate, applyFilter]);

  const updateFilterCount = (surgeryTag, paymentModes, surgeryCall) => {
    const totalCount = [...surgeryTag, ...paymentModes, ...surgeryCall].filter(Boolean).length;
    setFilterCount(totalCount > 0 ? `+${totalCount}` : '');
  };

  const convertFilterHeaderToType = (filterHeader) => {
    const words = filterHeader.split(' ');
    return words.map((word) => word.toLowerCase()).join('');
  };

  const handleSelectAll = (event, type) => {
    const isChecked = event.target.checked;
    const filterIndex = commonData.result.decentralizeFilters.findIndex(
      (filter) => convertFilterHeaderToType(filter.filterHeader) === type
    );

    if (filterIndex > -1) {
      const optionArray = commonData?.result?.decentralizeFilters[filterIndex].filterOptions || [];
      const updatedChecked = new Array(optionArray.length).fill(isChecked);
      
      if (type === 'statustag') {
        setSurgeryTagChecked(updatedChecked);
        setSurgeryTagValues(isChecked ? optionArray.map((item) => item.value) : []);
        updateFilterCount(updatedChecked, paymentModesChecked, surgeryCallChecked);
      } else if (type === 'paymentmode') {
        setPaymentModesChecked(updatedChecked);
        setPaymentModesValues(isChecked ? optionArray.map((item) => item.value) : []);
        updateFilterCount(surgeryTagChecked, updatedChecked, surgeryCallChecked);
      } else if (type === 'surgerycall') {
        setSurgeryCallChecked(updatedChecked);
        setSurgeryCallValues(isChecked ? optionArray.map((item) => item.value) : []);
        updateFilterCount(surgeryTagChecked, paymentModesChecked, updatedChecked);
      }
    }
  };

  const handleChildChange = (event, index, type) => {
    if (type === 'statustag') {
      const updatedChecked = [...surgeryTagChecked];
      updatedChecked[index] = event.target.checked;
      setSurgeryTagChecked(updatedChecked);

      if (event.target.checked) {
        setSurgeryTagValues([...surgeryTagValues, event.target.value]);
      } else {
        setSurgeryTagValues(surgeryTagValues.filter(value => value !== event.target.value));
      }

      updateFilterCount(updatedChecked, paymentModesChecked, surgeryCallChecked);
    } else if (type === 'paymentmode') {
      const updatedChecked = [...paymentModesChecked];
      updatedChecked[index] = event.target.checked;
      setPaymentModesChecked(updatedChecked);

      if (event.target.checked) {
        setPaymentModesValues([...paymentModesValues, event.target.value]);
      } else {
        setPaymentModesValues(paymentModesValues.filter(value => value !== event.target.value));
      }

      updateFilterCount(surgeryTagChecked, updatedChecked, surgeryCallChecked);
    } else {
      const updatedChecked = [...surgeryCallChecked];
      updatedChecked[index] = event.target.checked;
      setSurgeryCallChecked(updatedChecked);

      if (event.target.checked) {
        setSurgeryCallValues([...surgeryCallValues, event.target.value]);
      } else {
        setSurgeryCallValues(surgeryCallValues.filter(value => value !== event.target.value));
      }

      updateFilterCount(surgeryTagChecked, paymentModesChecked, updatedChecked);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null); // Set expanded panel or collapse if already expanded
  };

  const handleChildren = (data, type) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        let checkedStatus = 
          type === 'statustag' ? surgeryTagChecked[index] :
          type === 'paymentmode' ? paymentModesChecked[index] :
          surgeryCallChecked[index];
        return (
          <FormControlLabel
            key={item.value}
            label={item.label}
            control={
              <Checkbox
                checked={checkedStatus}
                onChange={
                  type === 'statustag'
                    ? (event) => handleChildChange(event, index, 'statustag')
                    : type === 'paymentmode'
                      ? (event) => handleChildChange(event, index, 'paymentmode')
                      : (event) => handleChildChange(event, index, 'surgerycall')
                }
                value={item.value}
              />
            }
          />
        );
      });
    }
    return null;
  };

  const handleApplyFilter = () => {
    setApplyFilter(!applyFilter);
    setFiltersOpen(!filtersOpen);
  };

  const handleClear = () => {
    const resetArray = (length) => new Array(length).fill(false);
    setSurgeryTagChecked(resetArray(surgeryTagChecked.length));
    setPaymentModesChecked(resetArray(paymentModesChecked.length));
    setSurgeryCallChecked(resetArray(surgeryCallChecked.length));
    setSurgeryTagValues([]);
    setPaymentModesValues([]);
    setSurgeryCallValues([]);
    updateFilterCount(resetArray(surgeryTagChecked.length), resetArray(paymentModesChecked.length), resetArray(surgeryCallChecked.length));
    // setFiltersOpen(!filtersOpen);
  };

  const handleOpenFilters = () => {
    setFiltersOpen(!filtersOpen);
  };


  return (
    <>
      <div className="centralFilters_boxContainer" onClick={handleOpenFilters}>
        <img
          src="https://img.pristyncare.com/amp_pictures%2FcentralFilterIcon.png"
          alt="Filter Icon"
        />
        <p style={{fontSize:'12px',margin:'0px',fontWeight:'500'}}>Filter {filterCount}</p>
      </div>
      {filtersOpen ? (
        <>
          <div className="centralFilters_Container">
            {
              commonData?.result?.decentralizeFilters && commonData?.result?.decentralizeFilters.length > 0 ?
                commonData?.result?.decentralizeFilters.map((filter, index) => {
                  let type = convertFilterHeaderToType(filter.filterHeader);

                  return (
                    <Accordion
                      key={index}
                      expanded={expandedAccordion === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <FormControlLabel
                          label={filter.filterHeader}
                          control={
                            <Checkbox
                              checked={
                                type === 'statustag'
                                  ? surgeryTagChecked.length > 0 && surgeryTagChecked.every(Boolean)
                                  : type === 'paymentmode'
                                    ? paymentModesChecked.length > 0 && paymentModesChecked.every(Boolean)
                                    : surgeryCallChecked.length > 0 && surgeryCallChecked.every(Boolean)
                              }
                              indeterminate={
                                type === 'statustag'
                                  ? surgeryTagChecked.some(Boolean) && !surgeryTagChecked.every(Boolean)
                                  : type === 'paymentmode'
                                    ? paymentModesChecked.some(Boolean) && !paymentModesChecked.every(Boolean)
                                    : surgeryCallChecked.some(Boolean) && !surgeryCallChecked.every(Boolean)
                              }
                              onChange={(event) => handleSelectAll(event, type)}
                            />
                          }
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                        />
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                          {handleChildren(filter.filterOptions, type)}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
                : ''
            }
            <div className='centralFilters_buttonGroup'>
              <Button className='centralFilters_clearButton' onClick={handleClear}>Clear All</Button>
              <Button className='centralFilters_applyButton' variant='contained' onClick={handleApplyFilter}>Apply</Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CentralFilters;
