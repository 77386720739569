import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { centralEquipmentsConstants } from "../../_constants";

export function centralInstrumentsData(
    state = centralGetInitialState("centralEquipmentData"),
    action
  ) {
    switch (action.type) {
      case centralEquipmentsConstants.FETCH_INSTRUMENTS_DATA_REQUESTING:
        return {
          ...state,
        };
      case centralEquipmentsConstants.CENTRAL_INSTRUMENTS_DATA_FETCHED:
          const { listData } = action;
        return {
          ...state,
          listData
        };
      case centralEquipmentsConstants.INSTRUMENTS_DATA_FAILED:
        return {
          ...state,
          instrumentsDataFetchingError: action.error,
        };
      default:
        return state;
    }
  }
  