import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_EQUIPMENT_URL;
let centralBaseCommonUrl = process.env.REACT_APP_CENTRAL_COMMON_URL;

export function centralGetInstrumentsService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/bd-dashboard/get-instruments-details`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

  export function centralUpdateInstrumentsService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/bd-dashboard/update-instruments`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

  export function centralAddOrDeleteInstrumentsService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/bd-dashboard/update-instruments-data`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

  export function centralLoadEMRCategoryService(params) {
    const {category, doctorId} = params
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${centralBaseCommonUrl}/emr/v1/loadEmrData?docCategory=${category?.toLowerCase()}&doctorId=${doctorId}&comData=instrument`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

  export function centralConsumableCategoryService(params) {
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseCommonUrl}/docApp/api/v2/getConsumablesList`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }