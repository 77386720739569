import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_BASE_URL;
let patientJourneyURL = process.env.REACT_APP_CENTRAL_UPLOAD_URL
export function getCentralPatientLists({hospitalId,startDate,endDate,paymentModesValues,surgeryTagValues,surgeryCallValues}) {
    
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const config  = {
      "hospitalId":hospitalId,
      "startDate" : startDate !=null || startDate==='' ? startDate : '', 
      "endDate" : endDate!=null || endDate===''  ? endDate : '',
      "paymentModes":paymentModesValues ? paymentModesValues : [],
      "patientJourneyTags":surgeryTagValues ? surgeryTagValues : [],
      "surgeryStatus" : surgeryCallValues ? surgeryCallValues : []
      // "pageNumber":0,
      // "pageSize":20
      }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...config }),
    };
    return fetch(
      `${centralBaseUrl}/getHospitalWiseData`,
      requestOptions
    )
      .then(handleResponse)
      .then(res => res)
      .catch(function (error) {
        console.error(error);
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

export function getPatientJourney(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${centralBaseUrl}/decentralised-event/?appointmentId=${params}`,
      requestOptions
    )
      .then(handleResponse)
      .then(res => res)
      .catch(function (error) {
        console.error(error);
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }
