import React, { useRef, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderCentralCommonComponent } from '../../helpers/utilities';
import { useDispatch, useSelector } from 'react-redux';
import usePromiseAllSettled from '../../customHooks/usePromiseAllSettled';
import moment from 'moment';
import { booleanStatesActions } from '../../../_actions';
import './centralAccordion.css'
import { CentralEquipmentModal } from '../CentralEquipmentModal/CentralEquipmentModal';
import { centralConsumableCategoryService, centralLoadEMRCategoryService } from '../../../_services';
import { EMR_TYPE_LIST } from '../CentralEquipmentModal/constant';
import { CentralInstructionsAccordion } from '../CentralEquipmentModal/CentralInstructionsAccordion';

export const CentralAccordion = ({type,data,allIds=''}) => {
  const [responses, errors, isLoading, fetchData] = usePromiseAllSettled()
  const dispatch = useDispatch()
  const booleanStates = useSelector(state=>state.booleanStates)
  const [isOpenEquipmentModal, setIsOpenEquipmentModal] = useState(false)
  const [instrumentsOptions, setInstrumentsOptions] = useState([]);
  const [equipmentType, setEquipmentType] = useState('')
  const allInputRef = useRef([]);
  const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
const [globalValues , setGlobalValues] = useState({
  actualOtStartTime:'',
  actualOtEndTime:''
})
  const handleAccordionBodyRender = (type,data, handleGlobalChanges, setGlobalValues, globalValues)=>{

      return data.map(item=>{
          return (type == item.title && item.details?
                  (<div key = {item.title} className='accordionBodyDataContainer'>
                      {item.details.map(detail => renderCentralCommonComponent(detail, handleGlobalChanges, setGlobalValues, globalValues))}
                  </div>)
                  :''
                  )
            })
      
  }

  const handleGlobalChanges = (name) => {
    allInputRef.current.push(name);
    allInputRef.current = [...new Set(allInputRef.current)];
  }
  
  const handleSubmit = (event, type)=>{
  event.preventDefault();
    const formData = new FormData(event.target);
    const changedValue = allInputRef.current
    const values = Object.fromEntries(formData.entries());
    let formDataObject = Object.fromEntries([...formData.entries()]);
    function isValidDate(dateString) {
      return moment(dateString, "MM/DD/YYYY hh:mm A", true).isValid();
    }
    
  let cashProofData = [];
  let deletedCashProofData = [];
  let uploadImagesData = []
  for (const property in formDataObject) {
      if (isValidDate(formDataObject[property])) {
          const formattedDate = moment(formDataObject[property], "MM/DD/YYYY hh:mm A").format("YYYY-MM-DDTHH:mm");
          formDataObject[property] = formattedDate;
      }
      if(property.includes('uploadImagesProperty')){
        let dataValue = ''
        let key = ''
        let value = ''
        if(property.includes('databucket')){
          dataValue = property.split(':~')
          key = dataValue[0].split("-")[1]
          value = dataValue[1]
          uploadImagesData.push(value)
          delete formDataObject[property];
        }
        dispatch(booleanStatesActions.updateIsRoomImagesUploaded(false))
          formDataObject[key] = uploadImagesData
      }
        if(property.includes('fileInfoNameProperty')){
          let key = property.split('fileInfoNameProperty-')[1]
          if(property.includes('databucket')){
            cashProofData.push({
              "url": key,
              "amount": formDataObject[property],
              "atTheTimeOf" : type?.toLowerCase() === 'admission' && booleanStates.isUploadedFile ? 'admission' : 'discharge'
            });
          delete formDataObject[property];
        }
        else{
          deletedCashProofData.push(key)
          delete formDataObject[property];
        }
        formDataObject.extraCashProof = {'cashProofData':cashProofData, 'deletedCashProofData':deletedCashProofData};
      }
  }

  const formDataObjectNew = Object.keys(formDataObject).reduce((acc, key) => {
    if (changedValue.some(item=>key.includes(item))) {
        acc[key] = formDataObject[key];
    }
    return acc;
}, {});

    if(booleanStates.isUploadedFile || booleanStates.isUploadedDisFile){
      if(booleanStates.isAmountFillFile && type?.toLowerCase() === 'admission'){
        if(changedValue.includes('totalCollectedOps')){
          delete formDataObjectNew?.totalCollectedOps;
        }
        fetchData(formDataObjectNew, allInputRef)
        dispatch(booleanStatesActions.updateIsAmountFill(false))
        dispatch(booleanStatesActions.updateIsUploaded(false))
      } 
      else if(booleanStates.isAmountFillDisFile && type?.toLowerCase() === 'discharge'){
        if(changedValue.includes('totalCollectedDisOps')){
          delete formDataObjectNew?.totalCollectedDisOps;
        }
        fetchData(formDataObjectNew, allInputRef)
        dispatch(booleanStatesActions.updateIsAmountDisFill(false))
        dispatch(booleanStatesActions.updateIsUploadedDisFile(false))
      }
      else{
          toast.error('Amount should not empty')
      }
    } else if(changedValue.includes('totalCollectedOps') || changedValue.includes('totalCollectedDisOps')){
      const value = formDataObjectNew.totalCollectedOps || formDataObjectNew.totalCollectedDisOps || ''
      if (value !== undefined && value !== null && (value === '' || value == 0)) {
        if(value==''){
          delete formDataObjectNew?.totalCollectedDisOps;
          delete formDataObjectNew?.totalCollectedOps;
        }
        fetchData(formDataObjectNew, allInputRef);
      } 
      else if (value) {
        toast.error('Upload proof if collection > 0');
      }
    }
    else{
      fetchData(formDataObjectNew, allInputRef)
    }
}

const handlePendingFields = () => {
  let totalPendingFields = 0;
  data.forEach(item => {
      let pendingFields = 0;
      if (type === item.title) {
          item && item.details.forEach(element => {
            if (
              (!element.value || (type === "Appointment Details" && element.key==='preAuth_status' && element.value?.toLowerCase() === 'preauth sent') ||
               (Array.isArray(element.value) && element.value.length === 0)) && 
              (element.functionType.includes('input') || element.functionType.includes('multi-select-dropDown'))
            ) {
              if(element.value !==0){

                pendingFields++;
              }
            }
              
          });
          totalPendingFields += pendingFields;
      }
  });
  return totalPendingFields;
};

const handleAddMoreButton = ()=>{
  setIsOpenEquipmentModal(true)
  let category = ''
        let disease = ''
        let doctorId = ''
        let type = ''
        let typeData = []
        if(reportingData && reportingData?.data?.length){
            category = reportingData?.data[0]?.Category?.value
            disease = reportingData?.data[0]?.disease
            doctorId = reportingData?.data[0]?.docAssigned
        }
        
        if(category){
            typeData = EMR_TYPE_LIST.filter(item=>item?.category?.toLowerCase()===category?.toLowerCase())
            type = typeData[0].type
        }
        if(type==='web' && doctorId && category){
            const params = {category:typeData[0].mappedCategory || category.toLowerCase(), doctorId}
            centralLoadEMRCategoryService(params).then(res=>{
                const {status='', result={}} = res;
                setEquipmentType(type)
                  if(status==='Success' && Object.keys(result).length && Object.keys(result.generic).length){
                    setInstrumentsOptions(result.generic)
                  }
                  })
                  .catch(err=>console.log('Fetching category data error', err))
        }
        else if(type==='native' && doctorId && category ){
            const params = {"categoryData":[{"categoryName": category,"disease":disease}],"docId":doctorId}
            centralConsumableCategoryService(params).then(res=>{
                const {status='', result=[]} = res;
                setEquipmentType(type)
                  if(status==='Success' && result.length>0)
                    setInstrumentsOptions(result)
                  })
                  .catch(err=>console.log('Category fetching error', err))
        }
}

const handleCloseModal = () => {
  setIsOpenEquipmentModal(false);
  setInstrumentsOptions([])
};

const handleSubmitMore = () => {
  console.log("Item added");
  // Add logic to handle adding more items
};


  return (
    <Accordion className='accordionContainer'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3476CD'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className='accordionHeaderContainer'
        >
          <Typography className='accordionHeaderText'>{type}</Typography>
          {handlePendingFields() ? <Typography className='accordionPendingFiendsText'>{"(Pending Fields: "+handlePendingFields()+")"}</Typography>:''}
        </AccordionSummary>
        <AccordionDetails className='accordionBodyDetails'>
          <form onSubmit={(e) => handleSubmit(e, type)} autoComplete="off">

           {type === "Instruments" ? <CentralInstructionsAccordion/> : handleAccordionBodyRender(type,data, handleGlobalChanges, setGlobalValues, globalValues)}
           {
             <div className={`accordionSaveButtonContainer ${ type === "Instruments" && "accordionAddMoreButtonContainer"}`}>
            {type === "Instruments"?
            <CentralEquipmentModal
              open={isOpenEquipmentModal}
              onClose={handleCloseModal}
              onAddMore={handleSubmitMore}
              onOpen = {handleAddMoreButton}
              instrumentsOptions = {instrumentsOptions}
              equipmentType = {equipmentType}
            />:
            (
              type !== "Patient Feedback" &&
              ((type === "Appointment Details" && handlePendingFields() > 0) || type !== "Appointment Details") && (
                <button type="submit" className="accordionSaveButton">Save</button>
              )
            )
            }
            </div>
           }
           
          </form>
        </AccordionDetails>
      </Accordion>
  )
}
