
export const jsonConstants = [
    {
        "status": "Success",
        "description": " CommonData values for deCentralisationScheme",
        "result": {
            "_id": "0otsccnXm1p",
            "_created_at": 1714219571416,
            "data": [
                {
                    "title": "Appointment Details",
                    "key": "app_details",
                    "details": [
                        {
                            "label": "Hospital Name",
                            "key": "hosp_name",
                            "getKey": "hospitalName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "modalDetails": {
                                "header": "",
                                "description": ""
                            },
                            "options": [],
                            "class": "",
                            "disable": false
                        },
                        {
                            "label": "Patient Name",
                            "key": "patient_name",
                            "getKey": "patientName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Disease",
                            "key": "disease",
                            "getKey": "patientDisease.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Doctor Name",
                            "key": "doctor_name",
                            "getKey": "doctorName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "MOP",
                            "key": "mode_of_payment",
                            "getKey": "payment_mode",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "green"
                        },
                        {
                            "label": "Surgery Call",
                            "key": "okForSurgery",
                            "getKey": "surgeryStatusIns.surgeryStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "ok-surgery",
                            "disable": false
                        },
                        {
                            "label": "Reimbursement Call Status",
                            "key": "call_status",
                            "getKey": "reimCallStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Date and Time",
                            "key": "appointment_date_time",
                            "getKey": "appointment_start_time",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "BD Name",
                            "key": "bd_name",
                            "getKey": "bd_name",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "IPD Posted At",
                            "key": "ipd_date_time",
                            "getKey": "ipdCreatedAt",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "isUTC": true,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "OT Slots(By Hospital)",
                            "key": "otSlots_hospital",
                            "getKey": "otSlots_hospital",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Cab boarding Time",
                            "key": "actualPickupTime",
                            "getKey": "actualPickupTime.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Expected Pt Arrival Time",
                            "key": "expectedPtArrivalTime",
                            "getKey": "expectedPatientArrivalSlots.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Admission Time",
                            "key": "admissionTime",
                            "getKey": "admissionTime",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Room Eligibility",
                            "key": "roomEligibility",
                            "getKey": "insuranceRoomType.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Tentative Amount",
                            "key": "tentativeAmount",
                            "getKey": "TentativeAmount",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Pre-Auth Status",
                            "key": "preAuth_status",
                            "getKey": "preAuthStatus",
                            "postKey": "preAuthStatus",
                            "value": "",
                            "functionType": "text-read-only",
                            "options": [
                                {
                                    "label": "Sent To TPA",
                                    "value": "Sent To TPA"
                                }
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Admission",
                    "key": "admission_details",
                    "details": [
                        {
                            "label": "Room Type (Insurance)",
                            "key": "room_type_insurance",
                            "getKey": "insuranceRoomType.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Total Collectible at Admission",
                            "key": "amount_collectible_admission",
                            "getKey": "proposedBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Surgery Call (Cash Cases)",
                            "key": "surgeryStatusIns",
                            "getKey": "surgeryStatusIns.surgeryStatus",
                            "postKey": "surgeryStatusIns",
                            "value": "",
                            "functionType": "input-dropDown",
                            "isHideField": false,
                            "options": [
                                {
                                    "label": "Pre-Sx financially cleared",
                                    "value": "Pre-Sx financially cleared"
                                },
                                {
                                    "label": "Admission Collection pending",
                                    "value": "Admission Collection pending"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Actual room admission time",
                            "key": "admittedToRoom",
                            "getKey": "admittedToRoom.value",
                            "postKey": "admittedToRoom",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual Arrival Time",
                            "key": "arrival_time",
                            "getKey": "patient_admission_time.value",
                            "postKey": "patient_admission_time",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Room Type",
                            "key": "room_type",
                            "getKey": "hospChatTemplateDetails.roomType.value",
                            "postKey": "roomTypeHos",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Private",
                                    "value": "Private"
                                },
                                {
                                    "label": "Semi-Private",
                                    "value": "Semi-Private"
                                },
                                {
                                    "label": "Triple Sharing",
                                    "value": "Triple Sharing"
                                },
                                {
                                    "label": "General Ward",
                                    "value": "General Ward"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Room Number",
                            "key": "hospitalRoom",
                            "getKey": "hospitalRoom",
                            "postKey": "hospitalRoom",
                            "value": "",
                            "functionType": "input-text",
                            "disable": false,
                        },
                        {
                            "label": "Samples Collected",
                            "key": "samplesCollected ",
                            "getKey": "sampleCollectionStatus.value",
                            "postKey": "sampleCollectionStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Attendant",
                            "key": "attendant",
                            "getKey": "attendant.value",
                            "postKey": "attendant",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Total Collected at Admission",
                            "key": "amount_collected_admission",
                            "getKey": "totalCollectedOps.value",
                            "postKey": "totalCollectedOps",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false,
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file",
                            "disable": false
                        },
                        {
                            "label": "Upload Room Picture",
                            "key": "roomImages",
                            "getKey": "roomImages",
                            "postKey": "roomImages",
                            "value": "",
                            "functionType": "upload-images",
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "OT Details",
                    "key": "ot_details",
                    "details": [
                        {
                            "label": "Extra Test",
                            "key": "extra_test",
                            "getKey": "extraTest.value",
                            "postKey": "extraTest",
                            "value": "",
                            "functionType": "input-text",
                            "disable": false
                        },
                        {
                            "label": "OT Date & Time(Central OPS)",
                            "key": "ot_central_date_time",
                            "getKey": "otTimeOps.value",
                            "postKey": "otTimeOps",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT start time",
                            "key": "actual_ot_start_time",
                            "getKey": "actualOtStartTime.value",
                            "postKey": "actualOtStartTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT end time",
                            "key": "actual_ot_end_time",
                            "getKey": "actualOtEndTime.value",
                            "postKey": "actualOtEndTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Reports Status",
                            "key": "reports",
                            "getKey": "reports.value",
                            "postKey": "reports",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Received (In)",
                                    "value": "Received (In)"
                                },
                                {
                                    "label": "Received (O)",
                                    "value": "Received (O)"
                                },
                                {
                                    "label": "Not Received",
                                    "value": "Not Received"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "PAC",
                            "key": "pac",
                            "getKey": "hospitalPACStatus.value",
                            "postKey": "hospitalPACStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Pending",
                                    "value": "Pending"
                                },
                                {
                                    "label": "Cleared",
                                    "value": "Cleared"
                                },
                                {
                                    "label": "Not Cleared",
                                    "value": "Not Cleared"
                                },
                                {
                                    "label": "Extra Test Suggested",
                                    "value": "Extra Test Suggested"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Anesthetics Available",
                            "key": "anestheticsAvailable",
                            "getKey": "anestheticAvailability.value",
                            "postKey": "anestheticAvailability",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "In-House",
                                    "value": "In-House"
                                },
                                {
                                    "label": "Outside",
                                    "value": "Outside"
                                },
                                {
                                    "label": "Not Available",
                                    "value": "Not Available"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Equipment Status",
                            "key": "equipment",
                            "getKey": "equipmentsStatus.value",
                            "postKey": "equipmentsStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Arranged (In)",
                                    "value": "Arranged (In)"
                                },
                                {
                                    "label": "Not Arranged",
                                    "value": "Not Arranged"
                                },
                                {
                                    "label": "Arranged (Out)",
                                    "value": "Arranged (Out)"
                                },
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Billing",
                    "key": "billing_details",
                    "details": [
                        {
                            "label": "Collection Gap",
                            "key": "collection_gap",
                            "getKey": "collectionGap",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "red"
                        },
                        {
                            "label": "Advance Collected at GHV",
                            "key": "amount_collected_at_ghv",
                            "getKey": "advanceCollectedByBD.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Proposed (Insurance)",
                            "key": "extra_cash_insurance",
                            "getKey": "extraCashProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Proposed Amount (from Insurance)",
                            "key": "proposed_amount",
                            "getKey": "pccscProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off (insurance)",
                            "key": "waivedOff_insurance",
                            "getKey": "extraCashWavedOff.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off Reasons (Insurance)",
                            "key": "waivedOff_reasons",
                            "getKey": "waiveOffReasons.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "modal-read-only",
                            "modalDetails": {
                                "header": "Waived off Reasons (Insurance)",
                                "description": ""
                            },
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Applicable (Insurance)",
                            "key": "extra_case_applicable_insurance",
                            "getKey": "extraCashApplicable.extraCashApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Applicable Amount",
                            "key": "applicable_amount",
                            "getKey": "pccscApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Proposed Package Amount (Cash cases)",
                            "key": "proposedPackageAmount",
                            "getKey": "proposedPackageAmount.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "OT Notes Status",
                            "key": "otNotesOverallStatus",
                            "getKey": "otNotesOverallStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "DS Status",
                            "key": "dischargeSummaryStatus",
                            "getKey": "dischargeSummaryStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Bill amount by Insurance",
                            "key": "final_bill_amount",
                            "getKey": "insFinalBillAmt.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Approval Amount",
                            "key": "final_approval_amount",
                            "getKey": "finalBillAmount",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Other Pristyn Payments",
                            "key": "other_payments",
                            "getKey": "CxEMIAmount.value",
                            "postKey": "CxEMIAmount",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Extra Test Required",
                            "key": "extraChargeReasons",
                            "getKey": "extraChargeReasons",
                            "postKey": "extraChargeReasons",
                            "value": "",
                            "functionType": "multi-select-dropDown",
                            "options": [
                                {
                                    "label": "No Extra Test",
                                    "value": "No Extra Test"
                                },
                                {
                                    "label": "Protection Kit Charges",
                                    "value": "Protection Kit Charges"
                                },
                                {
                                    "label": "Blood Transfusion + Procurement Charges",
                                    "value": "Blood Transfusion + Procurement Charges"
                                },
                                {
                                    "label": "MRI with Contrast Charges",
                                    "value": "MRI with Contrast Charges"
                                },
                                {
                                    "label": "Normal MRI Charges",
                                    "value": "Normal MRI Charges"
                                },
                                {
                                    "label": "CT Scan Charges",
                                    "value": "CT Scan Charges"
                                },
                                {
                                    "label": "2D Echo",
                                    "value": "2D Echo"
                                },
                                {
                                    "label": "Colonoscopy and Endoscopy",
                                    "value": "Colonoscopy and Endoscopy"
                                },
                                {
                                    "label": "Doppler Test",
                                    "value": "Doppler Test"
                                },
                                {
                                    "label": "Biopsy Charges (Small, Medium, Large)",
                                    "value": "Biopsy Charges (Small, Medium, Large)"
                                },
                                {
                                    "label": "Endoscopy",
                                    "value": "Endoscopy"
                                },
                                {
                                    "label": "HBSAG (Rapid)",
                                    "value": "HBSAG (Rapid)"
                                },
                                {
                                    "label": "HIV Test",
                                    "value": "HIV Test"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Extra Charge Collected",
                            "key": "extra_charge_collected",
                            "getKey": "extraChargeCollected.value",
                            "postKey": "extraChargeCollected",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Reason for difference in Package Amount (Cash cases)",
                            "key": "different_package_amount_reasons",
                            "getKey": "reasonForDifferencePackageAmount.value",
                            "postKey": "reasonForDifferencePackageAmount",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Package Changed by BD",
                                    "value": "Package Changed by BD"
                                },
                                {
                                    "label": "Extra Stay Charges",
                                    "value": "Extra stay charges"
                                },
                                {
                                    "label": "Surgery Change",
                                    "value": "Surgery Change"
                                },
                                {
                                    "label": "Conservative Case",
                                    "value": "Conservative Case"
                                },
                                {
                                    "label": "Patient paid extra by mistake",
                                    "value": "Patient paid extra by mistake"
                                },
                                {
                                    "label": "Patient paying Less due to infra issue/ hospital issue",
                                    "value": "Patient paying Less due to infra issue/ hospital issue"
                                },
                                {
                                    "label": "Not Applicable",
                                    "value": "Not Applicable"
                                }
                            ],
                            "disable": false
                        },
                    ]
                },
                {
                    "title": "Discharge",
                    "key": "discharge_details",
                    "details": [
                        {
                            "label": "Total Collectible at Discharge",
                            "key": "total_collectible_at_discharge",
                            "getKey": "applicableBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PDS document",
                            "key": "pds_document",
                            "getKey": "pds_document",
                            "postKey": "",
                            "value": "",
                            "functionType": "summary-modal",
                            "disable": false
                        },
                        {
                            "label": "Fit For Discharge",
                            "key": "fitForDischarge",
                            "getKey": "patientFitForDischarge",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Bill Status",
                            "key": "finalBillStatus",
                            "getKey": "finalBillStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Extra Charge",
                            "key": "extraCharge.value",
                            "getKey": "extraCharge.value",
                            "postKey": "extraCharge",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Reason of Extra charge at Discharge",
                            "key": "reasonForExtraChargeAtDischarge",
                            "getKey": "reasonForExtraChargeAtDischarge",
                            "postKey": "reasonForExtraChargeAtDischarge",
                            "value": "",
                            "functionType": "multi-select-dropDown",
                            "options": [
                                {
                                    "label": "No Other Charges",
                                    "value": "No Other Charges"
                                },
                                {
                                    "label": "Protection Kit Charges",
                                    "value": "Protection Kit Charges"
                                },
                                {
                                    "label": "Blood Transfusion + Procurement Charges",
                                    "value": "Blood Transfusion + Procurement Charges"
                                },
                                {
                                    "label": "Extended OT charges",
                                    "value": "Extended OT charges"
                                },
                                {
                                    "label": "Support Staff Charges",
                                    "value": "Support Staff Charges"
                                },
                                {
                                    "label": "Anesthetics charges",
                                    "value": "Anesthetics charges"
                                },
                                {
                                    "label": "Extra Stay charges",
                                    "value": "Extra Stay charges"
                                },
                                {
                                    "label": "Extra doctor charges ( Cardiac, Gastro etc)",
                                    "value": "Extra doctor charges ( Cardiac, Gastro etc)"
                                },
                                {
                                    "label": "ICU charges",
                                    "value": "ICU charges"
                                },
                            ],
                            "disable": false
                        },
                        {
                            "label": "Discharge Cab Booking Status",
                            "key": "discharge_cab_booking_status",
                            "getKey": "cabBookingStatus.value",
                            "postKey": "cabBookingStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Actual Date of discharge (Date and time)",
                            "key": "date_of_discharge",
                            "getKey": "finalDateOfDischarge.value",
                            "postKey": "finalDateOfDischarge",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Total Collected at Discharge",
                            "key": "total_collected_at_discharge",
                            "getKey": "totalCollectedDisOps.value",
                            "postKey": "totalCollectedDisOps",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file-discharge",
                            "disable": false
                        }
                    ]
                },
                {
                    "title": "Instruments",
                    "key": "instruments_details",
                    "details": []
                },
                {
                    "title": "Patient Feedback",
                    "key": "patient_feedback",
                    "details": [
                        {
                            "label": "SubStatus",
                            "key": "subStatus",
                            "getKey": "patientFeedback.subStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Trigger Time",
                            "key": "triggerTime",
                            "getKey": "patientFeedback.triggerTime",
                            "postKey": "",
                            "value": "",
                            "format": "DD MMM, hh:mm A",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Source",
                            "key": "source",
                            "getKey": "patientFeedback.source",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Received At",
                            "key": "receivedAt",
                            "getKey": "patientFeedback.receivedAt",
                            "postKey": "",
                            "value": "",
                            "format": "DD MMM, hh:mm A",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                    ]
                }
            ],
            "name": "deCentralisationScheme",
            "decentralizeFilters": [
                {
                    "filterHeader": 'Status Tag',
                    "filterOptions": [{
                        "label": "Tentative IPD",
                        "value": "Tentative IPD"
                    },
                    {
                        "label": "Deficit",
                        "value": "Deficit"
                    },
                    {
                        "label": "Boarded the Cab",
                        "value": "Boarded the Cab"
                    },
                    {
                        "label": "Admitted",
                        "value": "Admitted"
                    },
                    {
                        "label": "PAC Cleared",
                        "value": "PAC Cleared"
                    },
                    {
                        "label": "PAC Not Cleared",
                        "value": "PAC Not Cleared"
                    },
                    {
                        "label": "Extra Test Suggested",
                        "value": "Extra Test Suggested"
                    },
                    {
                        "label": "Pre-Sx financially cleared",
                        "value": "Pre-Sx financially cleared"
                    },
                    {
                        "label": "In Surgery",
                        "value": "In Surgery"
                    },
                    {
                        "label": "Surgery Done",
                        "value": "Surgery Done"
                    },
                    {
                        "label": "Billing in progress",
                        "value": "Billing in progress"
                    },
                    {
                        "label": "Fit for Discharge",
                        "value": "Fit for Discharge"
                    },
                    {
                        "label": "Discharge processed",
                        "value": "Discharge processed"
                    },
                    {
                        "label": "Discharge in progress",
                        "value": "Discharge in progress"
                    },
                    {
                        "label": "Discharged",
                        "value": "Discharged"
                    },
                    {
                        "label": "Happy patients",
                        "value": "Happy patients"
                    }
                    ]
                },
                {
                    "filterHeader": 'Payment Mode',
                    "filterOptions": [
                        {
                            "value": 'Cash',
                            "label": 'Cash',

                        },
                        {
                            "value": 'Cashless',
                            "label": 'Cashless',

                        },
                        {
                            "value": 'Copay - Cashless',
                            "label": 'Copay - Cashless',

                        },
                        {
                            "value": 'Reimbursement',
                            "label": 'Reimbursement',
                        }
                    ]
                },
                {
                    "filterHeader": 'Surgery Call',
                    "filterOptions": [
                        {
                            "value": 'Ok for Surgery',
                            "label": 'Ok for Surgery',

                        },
                        {
                            "label": "Pre-Sx financially cleared",
                            "value": "Pre-Sx financially cleared"
                        },
                        {
                            "value": 'Documents Pending',
                            "label": 'Documents Pending',

                        },
                        {
                            "value": 'Not Ok For Surgery',
                            "label": 'Not Ok For Surgery',

                        },

                    ]
                }
            ]
        }
    }
]