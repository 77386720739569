import React, { useContext, useEffect, useState } from 'react'
import "./centralCommonHeader.css"
import { Avatar, IconButton , InputAdornment} from '@mui/material'
import backActionIcon from '../../../assets/backActionIcon.png';
import centralSearchIcon from '../../../assets/centralSearchIcon.png';
import closeIcon from "../../../assets/centralSearchCloseIcon.png"
import patientAvatarIcon from "../../../assets/patientAvatar.png"
import ChatIcon from '@mui/icons-material/Chat';
import { chatBoxActions, configActions, homeStateActions, hospitalActions, patientActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mainCacheConstants, useReduxCacheAndDispatch } from '../../../_helpers';
import { chatBoxConstants } from '../../../_constants';
import { WebSocketContext } from '../../../contexts/WebSocket';
import { getPatientList } from '../../../_services';
import { toast } from 'react-toastify';
import CentralFilters from '../CentralFilters';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import { formatCommonHeaderDate } from '../../helpers/utilities';
import { useGetRefreshState } from '../../../Routing/useGetRefreshState';
import CloseIcon from '@mui/icons-material/Close';

export const CentralCommonHeader = ({
  title = '',
  backIcon = false,
  backArrowFunction,
  avatarIcon = false,
  searchIcon = false,
  isChatIcon = false,
  searchFunction,
  clearFunction,
  filterIcon = false,
  dateFilter = false,
  itemCount=''
}) => {
  const [searchActive, setSearchActive] = useState(false)
  const [hasSearchIcon, setHasSearchIcon] = useState(searchIcon)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const isApp = localStorage.getItem("isApp");
  // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { sendSocketMessage } = useContext(WebSocketContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [manualDate, setManualDate] = useState(null);
  const [todayIsActive,setTodayIsActive] = useState(false)
  const [yestIsActive,setYestIsActive] = useState(false)
  const [tommIsActive,setTommIsActive] = useState(false)
  const today = dayjs().startOf('day'); 
  const yesterday = dayjs().subtract(1, 'day').startOf('day'); 
  const tomorrow = dayjs().add(1, 'day').startOf('day'); 

  useEffect(() => {
    const loadLocale = async () => {
      const dayjsLocale = await import('dayjs/locale/en-gb');
      dayjs.locale('en-gb');
    };

    if (dateFilter) {
      loadLocale();
    } else {
      // Reset to default locale (you can change 'en' to your preferred default)
      dayjs.locale('en');
    }
  }, [dateFilter]);

  const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
  const centralHospitalData = useSelector(state => state.centralHospitalData.listData)
  let patientList = useSelector(state => state.centralPatients)
  const patientListData = patientList?.centralPatients?.data;
  const hospitalList = useSelector((state) => state.hospitals.hospitalList)
  const {getRefreshedPage } = useGetRefreshState({source:"A"})
  
  const handleClearSearch = () => {
    clearFunction()
    setHasSearchIcon(!hasSearchIcon)
    setSearchActive(false)
  }

  const handleChatIcon = async ()=>{
    let appointmentId = ''
    let patientId = ''
    let leadId = ''
    let currHospitalId = ''
    if(reportingData && reportingData?.message==='Success' && reportingData?.data?.length>0){
      currHospitalId = reportingData?.data[0].hospitalId
      leadId = reportingData?.data[0].lead_id
      patientId = reportingData?.data[0].patient_id
      appointmentId = reportingData?.data[0].appointment_id
    }
    
    const paramsData={
      selectedHospitalId: currHospitalId
    } 
    cacheAndDispatch({
      action: patientActions.getPatientList,
      params: {
        selectedHospitalId: currHospitalId,
        sid:'',
      },
      cacheOf: mainCacheConstants.patientListsCache,
      key: '',
    });

      cacheAndDispatch({
          action: hospitalActions.getHospitalList,
          params: {
              mobile: localStorage.getItem('userMobile'),
          },
          cacheOf: mainCacheConstants.hospitalListCache,
          key: localStorage.getItem('userMobile'),
      });

    getPatientList(paramsData)
    .then(res=>{
  if(Object.values(res?.patients).length){
    const patientsDetails = Object.values(res?.patients).flat() 
    const info = patientsDetails.filter(item=>item._id===appointmentId)
    if(info.length){
    dispatch(chatBoxActions.privateChatOff())
    getRefreshedPage({hospId:currHospitalId,apptId:appointmentId});
    dispatch(
      homeStateActions.setState({
        showChatBox: true,
      })
    );
    let messageSocketPayload = {
      action: "readAppointment",
      payload: {
        hospitalId: currHospitalId,
        appointmentId: appointmentId
      }
    }
    sendSocketMessage(messageSocketPayload);
    dispatch(patientActions.updatePatientsReadCount({ appointmentId, count: 0 }))

    dispatch(homeStateActions.setScreenName({ hospitalList: false, patientList: false, chatBox: true }))

  dispatch(
    patientActions.setPatientInfo({
      appointmentId,
      patientId,
      leadId,
      allInfo: info[0],
    })
  );
  
  let patientParam = {
    appointmentId,
    patientId,
    leadId,
  };
  dispatch(patientActions.getPatientInfoAPIData(patientParam));

    let params ={
      appointmentId,
      leadId,
      numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
      hospitalId: currHospitalId
    }


    cacheAndDispatch({
      action: chatBoxActions.getPatientMessages,
      params,
      cacheOf: mainCacheConstants.chatBoxMessagesCache,
      key: appointmentId || leadId,
    });

if (hospitalList && hospitalList.length > 0) {
            
            hospitalList.forEach((item) => {
                if (item.hospital_id === currHospitalId) {

                    dispatch(
                        hospitalActions.setCurrentHospitalData({
                            data: item,
                        })
                    );
                }
            });
        }
  
    navigate("/chatbox")
  }
  else{
    toast.error("Could not retrieve patient details.")
  }
  }else{
    toast.error("No patient details available.")
  }
})
.catch(err=>{
  toast.error("Patient details could not be found.")
  console.log('Unable to navigate', err)
}).finally(()=> dispatch(configActions.hideSpinningLoader()))
  }

  const handleChatFunction = ()=>{
    dispatch(configActions.showSpinningLoader())
    if(isApp && window?.JBridge){
      let appointmentId = ''
      let patientId = ''
      let leadId = ''
      let currHospitalId = ''
      if(reportingData && reportingData?.message==='Success' && reportingData?.data?.length>0 && centralHospitalData.length,patientListData.length ){
        currHospitalId = reportingData?.data[0].hospitalId
        leadId = reportingData?.data[0].lead_id
        patientId = reportingData?.data[0].patient_id
        appointmentId = reportingData?.data[0].appointment_id

        const hospitalData = centralHospitalData.filter(item=>item.hospitalId===currHospitalId)
        const patientData = patientListData.filter(item=>item.appId===appointmentId)
        if(hospitalData.length && patientData.length){
          const objString = JSON.stringify({hospitalInfo:hospitalData[0], patientInfo:patientData[0]});
          window.JBridge.handleBackToAppMsg(objString)
        }
        else{
          toast.error("Could not retrieve patient details.")
        }
      }
      dispatch(configActions.hideSpinningLoader())
    }
    else{
        handleChatIcon()
        dispatch(configActions.hideSpinningLoader())
    }
}

  const setYesterday = () => {
    setManualDate(dayjs().subtract(2, 'day'));
    setSelectedDate(dayjs().subtract(1, 'day'))
    setTodayIsActive(false)
    setYestIsActive(true)
    setTommIsActive(false)
  }
  const setToday = () => {
    setManualDate(dayjs().subtract(1, 'day'));
    setSelectedDate(dayjs())
    setTodayIsActive(true)
    setYestIsActive(false)
    setTommIsActive(false)
  }
  const setTomorrow = () => {
    setManualDate(dayjs());
    setSelectedDate(dayjs().add(1, 'day'))
    setTodayIsActive(false)
    setYestIsActive(false)
    setTommIsActive(true)
  }

  const updateDateStates = (date) => {
    setTodayIsActive(date.isSame(today, 'day'));
    setYestIsActive(date.isSame(yesterday, 'day'));
    setTommIsActive(date.isSame(tomorrow, 'day'));
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    setManualDate(newDate)
    updateDateStates(dayjs(newDate));
  }
  const handleDateClear = (event) => {
    event.stopPropagation(); 
    setManualDate(null) 
    setSelectedDate(null);
    setTodayIsActive(false);
    setYestIsActive(false);
    setTommIsActive(false);
  };

  return (
    <>
      <div className='centralCommonHeader_container' style={{ justifyContent: avatarIcon ? 'unset' : 'space-between' }}>
        {!searchActive ?
          <div className={`centralCommonHeader_header ${avatarIcon ? 'centralCommonHeader_absolute' : ''}`}>
            {
              backIcon ?
                <img src={backActionIcon} alt="backIcon" onClick={backArrowFunction} /> : ' '
            }
            {
              !avatarIcon ?
                <h3 className='hospitalNameHeading'>
                  <span className='hospitalNameHeadingText'>{title}</span>
                  {itemCount ? <span className="itemCount">({itemCount})</span> : ''}
                </h3> : ''
            }
          </div>
          :
          <div className="search-input-container" style={{ flex: 2, backgroundColor: "rgba(255,255,255,0.3)", alignItems: 'center', fontSize: '14px', color: '#fff', borderRadius: '50px' }}>
            <input
              name="search"
              id="search"
              placeholder="Search Hospitals"
              style={{ paddingLeft: "20px" }}
              onInput={(event) => searchFunction(event.target.value)}
            />
            <img src={closeIcon} alt="clearIcon" className="central_clearIcon" onClick={handleClearSearch} />
          </div>
        }
        {hasSearchIcon ?
          <img src={centralSearchIcon} alt="searchIcon" onClick={() => { setSearchActive(!searchActive); setHasSearchIcon(!hasSearchIcon) }} /> : ' '
        }
        {
              avatarIcon ?
                <div className='centralCommonHeader_patientAvatar'>
                  <img src={patientAvatarIcon} alt="avatarIcon"/>
                  <h3>
                    {title}
                  </h3>
                </div> : ''
            }
          {
            isChatIcon ? 
            <IconButton onClick={handleChatFunction}>
              <ChatIcon sx={{color:'white'}}/> 
            </IconButton>
            :""
          }
          {
          filterIcon ?
            <CentralFilters selectedDate={manualDate} /> : ''
        }
      </div>
      {dateFilter ?
        <div className='centralCommonHeader_datePicker_container'>
          <p className='centralCommonHeader_datePicker_header'>Admission date</p>
          <div className='centralCommonHeader_datePicker'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
                onAccept={(newDate) => handleDateChange(newDate)}
                minDate={dayjs().subtract(10, 'day')}
                maxDate={dayjs().add(10, 'day')}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      maxWidth: 148,
                      height: 28,
                      background: 'white',
                      '& .MuiInputBase-input': {
                        height: 28,
                        padding: '0 14px',
                        fontSize:'12px !important'
                      },
                      '& .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
                        padding: '0 14px',
                      },
                      '& .MuiInputAdornment-root': {
                        height: 28,
                        '& .MuiSvgIcon-root': {
                          fontSize: 18,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        top: 0,
                        transform: 'none',
                        padding: '5px 0 0 15px',
                        fontSize:'12px !important'
                      },
                      '& .MuiOutlinedInput-root': {
                        fontSize:'12px !important'
                      },
                      '& .MuiInputLabel-root.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -8px) scale(0.75)',
                        padding: 0,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectedDate ? 
                            <CloseIcon fontSize='small' style={{ color: '#3476CD' }} onClick={handleDateClear}
                         />
                     :<EventIcon fontSize='small' style={{ color: '#3476CD' }} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <Box

              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                gap: '5px',
                padding: '5px 0',
              }}
            >
              <Typography
                onClick={setYesterday}
                sx={{
                  cursor: 'pointer',
                  border:  `0.5px solid ${yestIsActive ? '#3476CD' :'#E3E3E3'}`,
                  backgroundColor: yestIsActive ?   '#D9EDFF' : '#fff',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  minWidth: '100px',
                  textAlign: 'center',
                  fontSize: '12px'
                }}
              >
                Yesterday
              </Typography>

              <Typography
                onClick={setToday}
                sx={{
                  cursor: 'pointer',
                  border:  `0.5px solid ${todayIsActive ? '#3476CD' :'#E3E3E3'}`,
                  backgroundColor: todayIsActive ?   '#D9EDFF' : '#fff',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  minWidth: '100px',
                  textAlign: 'center',
                  fontSize: '12px'
                }}
              >
                Today
              </Typography>

              <Typography
                onClick={setTomorrow}
                sx={{
                  cursor: 'pointer',
                  border:  `0.5px solid ${tommIsActive ? '#3476CD' :'#E3E3E3'}`,
                  backgroundColor: tommIsActive ?   '#D9EDFF' : '#fff',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  minWidth: '100px',
                  textAlign: 'center',
                  fontSize: '12px'
                }}
              >
                Tomorrow
              </Typography>
            </Box>
          </div>
        </div>
        :
        ''
      }
    </>
  )
}
