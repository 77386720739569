import React, { useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { CentralInputField } from "../CentralInputField/CentralInputField";
import { centralUploadFilesService } from "../../../_services/centralServices/centralUploadFiles.service";
import { CentralModal } from "../CentralModal/CentralModal";
import { booleanStatesActions } from "../../../_actions";
import { centralGetSignedUrlService } from "../../../_services";
import "./centralUploadFile.css";
import { toast } from "react-toastify";

export const CentralFileUpload = (props) => {
  const dispatch = useDispatch();
  const { uniqueKey = '', jsonDetails = "", isAmountShow = true, handleGlobalChanges=()=>{}} = props;
  const [files, setFiles] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [finalUrls, setFinalUrls] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [isRemoved, setIsRemoved] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [currentModalAmount, setCurrentModalAmount] = useState(null);

  let allFilesUrls = [];
  let allCashProofData = [];
  let reportingData = useSelector((state) => state.centralReportingData);
  let isUploadedFile = useSelector(
    (state) => state.booleanStates.isUploadedFile
  );
  let isUploadedDisFile = useSelector(
    (state) => state.booleanStates.isUploadedDisFile
  );

  let isUploadedRoomImages = useSelector(
    (state) => state.booleanStates.isUploadedRoomImages
  );

  let lead_id = "";
  if (reportingData && reportingData?.reportingData?.data?.length) {
    lead_id = reportingData?.reportingData?.data[0]?.lead_id;
  }

  function handleMultipleChange(event) {
    const uploadedFiles = [];
    
    const filesArray = Array.from(event.target.files);
    if (filesArray && (11 >filesArray.length > 0)) {
      setIsUpload(true);
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          uploadedFiles.push({ name: file.name, url: file });
          if (uploadedFiles.length === filesArray.length) {
            setFiles((pre) => [...pre, ...uploadedFiles]);
            setAmounts((prev) => [
              ...prev,
              ...Array(uploadedFiles.length).fill(null),
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
    else {
      toast.error('Can not upload more than 10 files')
    }
    handleGlobalChanges(isAmountShow?'extraCashProof':uniqueKey)
  }

  const handleFileCrossButton = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFinalUrls((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setAmounts((prevAmounts) => prevAmounts.filter((_, i) => i !== index));
  };

  const handleInputAmount = (index, value) => {
    handleGlobalChanges('extraCashProof')
    setAmounts((prevAmounts) => {
      const newAmounts = [...prevAmounts];
      newAmounts[index] = parseFloat(value);
      return newAmounts;
    });
  };

  useEffect(() => {
    if(uniqueKey==='admissionUploadFile'){
      if(amounts && amounts.length > 0){
        dispatch(booleanStatesActions.updateIsUploaded(true));
        if(amounts.every(amount => amount !== null && amount >= 0 && amount !== NaN)){
          dispatch(booleanStatesActions.updateIsAmountFill(true));
        }
        else{
          dispatch(booleanStatesActions.updateIsAmountFill(false));
        }
      }
      else{
        dispatch(booleanStatesActions.updateIsUploaded(false));
      }
    }
    else if(uniqueKey==='dischargeUploadFile'){
      if(amounts && amounts.length > 0){
        dispatch(booleanStatesActions.updateIsUploadedDisFile(true));
        if(amounts.every(amount => amount !== null && amount >= 0 && amount !== NaN)){
          dispatch(booleanStatesActions.updateIsAmountDisFill(true));
        }
        else{
          dispatch(booleanStatesActions.updateIsAmountDisFill(false));
        }
      }
      else{
        dispatch(booleanStatesActions.updateIsUploadedDisFile(false));
      }
    }

  }, [amounts]);

  // const totalAmount = amounts.reduce((acc, amount) => acc + amount, 0);

  useEffect(() => {
    if (files.length > 0 && isUpload && lead_id) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i].url);
      }
      formData.append("onlyUrl", "true");
      formData.append("lead_id", lead_id);

      centralUploadFilesService(formData)
        .then((res) => {
          const data = res?.data;
          if (data?.length > 0) {
            const urls = data.map((file) => ({
              name: file.fileName,
              url: file.Url,
            }));
            setFinalUrls(urls);
            setIsUpload(false);
          }
          else{
            setIsUpload(false);
            setFinalUrls([])
            setFiles([])
            setAmounts([])
            setIsRemoved([])
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    }
  }, [files]);

  if (jsonDetails?.value?.length && jsonDetails?.key!=="roomImages") {
    let data = jsonDetails?.value;
    data = data.sort((a, b) => new Date(a.collectedAt).getTime() - new Date(b.collectedAt).getTime());
    data.forEach((item) => {
      if (item.url && item.url.length > 0) {
        allFilesUrls.push(...item.url);
      }
    });
    data.filter((item) => {
      if (item.cashProofData && item.cashProofData.length > 0) {
        const reverseData = [...item.cashProofData].reverse();
        allCashProofData.push(...reverseData);
      }
    });
    if(uniqueKey === 'admissionUploadFile'){
      allCashProofData = allCashProofData.filter(item=>item?.atTheTimeOf?.toLowerCase()==='admission')
    }
    else if(uniqueKey === 'dischargeUploadFile'){
      allCashProofData = allCashProofData.filter(item=>item?.atTheTimeOf?.toLowerCase()==='discharge')
    }
  }
  else if(jsonDetails?.value?.length && jsonDetails?.key==="roomImages"){
    let data = jsonDetails?.value.filter(ele=>ele)
    data = data.sort((a, b) => new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime());
    allCashProofData = data.map(item=>{
      if(item?.removedAt){
        return ({_id:item.removedAt, url:item.url, status:'Deleted'})
      }
      else {
        return ({_id:item.uploadedAt, url:item.url})
      }
    }
    )
  }

  const findNameFromUrl = (url) => {
    const parts = url.split("/");
    const fileName = parts[3];
    return fileName;
  };

  useEffect(() => {
    setIsRemoved(Array(allCashProofData.length).fill(false));
  }, []);

  const handleSignedUrl = (url) =>{
    const params = {
      "urls":[url]
    }
    centralGetSignedUrlService(params).then(res=>{
      const {status='', result=[]} = res;
        if(status==='Success' && result.length>0)
          window.open(result[0]) 
        })
        .catch(err=>console.log('Signed url error', err))
  }

  const renderPreData = () => {
    const handleModalOpen = (index, amount) => {
      setCurrentFileIndex(index);
      setCurrentModalAmount(amount)
      setIsModalOpen(true);
    };
    const handleModalClose = () => {
      setIsModalOpen(false);
    };
    const handleFileRemovedButton = (index) => {
      const newIsRemoved = [...isRemoved];
      newIsRemoved[index] = true;
      setIsRemoved(newIsRemoved);
    };

    const handleModalSubmit = () => {
      handleFileRemovedButton(currentFileIndex);
      handleModalClose();
      handleGlobalChanges(isAmountShow ? 'extraCashProof':uniqueKey)
    };

    return (
      <div>
        {allCashProofData &&
          allCashProofData.length > 0 &&
          allCashProofData.reverse().map((ele, index) => (
            <div
              key={ele._id + index} 
              className={`filePreviewContainer filePreviewDataContainer ${
                ele.status === "Deleted" || isRemoved[index]
                  ? "deletedBackground"
                  : ""
              }`}
            >
              <div className={isAmountShow ?"filePreviewChildContainer":"filePreviewImagesContainer"}>
                <span className="filePreviewProofText">{index + 1}</span>
                <div className="filePreviewNameContainer">
                  {ele?.url?.includes(".pdf") ? (
                    <PictureAsPdfIcon
                      fontSize="small"
                      sx={{ color: "#EA4336" }}
                    />
                  ) : (
                    <ImageIcon fontSize="small" sx={{ color: "#EA4336" }} />
                  )}
                  <div
                    className="filePreviewName"
                    onClick={()=>handleSignedUrl(ele.url)}
                  >
                    {findNameFromUrl(ele.url)}
                  </div>
                </div>
              </div>
              <div className="filePreviewAmountContainer">
                {isAmountShow ?  <span className="onlyAmountText">Rs. {ele.amount}</span>:""}
                {ele.status === "Deleted" ? (
                  <span className="fileRemovedText">Removed</span>
                ) : isRemoved[index] ? (
                  <input
                    name={isAmountShow ? `fileInfoNameProperty-${ele._id}` :`uploadImagesProperty-${uniqueKey}:~${ele?.url}~Deleted`}
                    readOnly
                    className="fileRemovedText"
                    value={"Removed"}
                  ></input>
                ) : (
                  <CentralModal
                    open={isModalOpen}
                    isModalFooterShow={true}
                    onSubmit={() => handleModalSubmit()}
                    onClose={handleModalClose}
                    buttonTitle="Yes"
                    cancelButtonTitle="No"
                    modalTitle={""}
                    isShowDivider={false}
                    sx={{minHeight:isAmountShow?'155px':'136px'}}
                    modalButton={
                      <span className="crossButtonContainer" style={{width:isAmountShow?'50px':'32px'}}>
                        <IconButton
                          sx={{ padding: "5px" }}
                          onClick={() => handleModalOpen(index, ele.amount)}
                        >
                          <CancelIcon
                            fontSize="small"
                            sx={{ color: "#9A9A9A" }}
                          />
                        </IconButton>
                      </span>
                    }
                  >
                {isAmountShow? <div className="modalBodyDataContainer">
                      Do you want to remove the amount of <br />
                      <span className="highLightAmount">
                        Rs. {currentModalAmount}
                      </span>
                      {reportingData?.activePatientName
                        ? ` from ${reportingData?.activePatientName} ?`
                        : ""}
                    </div>:<div className="modalBodyDataContainer">Do you want to remove room image?</div>}
                  </CentralModal>
                )}
              </div>
            </div>
          ))}
        {/* {allFilesUrls &&
          allFilesUrls.length > 0 &&
          allFilesUrls.map((ele, index) => (
            <div
            key={ele + index}
              className="filePreviewChildContainer filePreviewUrlContainer"
            >
              <span className="filePreviewProofText">
                {allCashProofData.length + (index + 1) || index + 1}
              </span>
              <div className="filePreviewNameContainer">
                {ele.includes(".pdf") ? (
                  <PictureAsPdfIcon
                    fontSize="small"
                    sx={{ color: "#EA4336" }}
                  />
                ) : (
                  <ImageIcon fontSize="small" sx={{ color: "#EA4336" }} />
                )}
                <div
                  className="filePreviewName"
                  onClick={()=>handleSignedUrl(ele)}
                >
                  {findNameFromUrl(ele)}
                </div>
              </div>
            </div>
          ))} */}
      </div>
    );
  };

  useEffect(() => {
    if((uniqueKey ==='dischargeUploadFile' && !isUploadedDisFile) || (uniqueKey ==='admissionUploadFile' && !isUploadedFile) || (uniqueKey==='uploadImages'&& !isUploadedRoomImages)){
      setFinalUrls([])
      setFiles([])
      setAmounts([])
      setIsRemoved([])
    }
  }, [isUploadedDisFile,isUploadedFile, isUploadedRoomImages])
  
  return (
    <div key={uniqueKey} className="fileUploadMainContainer">
      <div className="fileUploadContainer">
        <span className="fileUploadHeading">
          {(jsonDetails && jsonDetails?.label) || "Upload Files"}
        </span>
        <label htmlFor={`upload-file-${uniqueKey}`} className="fileUploadInputButton">
          <UploadIcon fontSize="small" /> Upload
        </label>
        <input
          type="file"
          accept="image/png,image/jpeg, image/jpg, application/pdf"
          multiple
          onChange={(e) => handleMultipleChange(e)}
          id={`upload-file-${uniqueKey}`}
          onClick={(event)=> { 
            event.target.value = null
       }}
        />
      </div>
      <div className="fileUploadShowContainer">
        {isUpload ? (
          <div className="fileUploadLoader">
            <CircularProgress fontSize="small" />
          </div>
        ) : (
          <>
            {
              finalUrls.length > 0 &&
              finalUrls.map((file, index) => (
                <div key={index} className="filePreviewContainer">
                  <div className={isAmountShow ?"filePreviewChildContainer":"filePreviewImagesContainer"}>
                    <span className="filePreviewProofText">{index + 1}</span>
                    <div className="filePreviewNameContainer">
                      {file.url.includes(".pdf") ? (
                        <PictureAsPdfIcon
                          fontSize="small"
                          sx={{ color: "#EA4336" }}
                        />
                      ) : (
                        <ImageIcon fontSize="small" sx={{ color: "#EA4336" }} />
                      )}
                      {isAmountShow?<div
                        className="filePreviewName"
                        onClick={()=>handleSignedUrl(file.url)}
                      >
                        {file.name}
                      </div>:<input
                      readOnly
                      name={
                        `uploadImagesProperty-${uniqueKey}:~${file.url}`
                      }
                      className="showFileName"
                      value={file.name}
                      onClick={()=>handleSignedUrl(file.url)}
                      />}
                    </div>
                  </div>
                  <div className="filePreviewAmountContainer">
                    {isAmountShow ? <CentralInputField
                      value={amounts[index]}
                      name={
                        `fileInfoNameProperty-${file.url}` ||
                        (jsonDetails && jsonDetails?.postKey)
                      }
                      sx={{ marginTop: "0px"}}
                      type={"number"}
                      startAdornment="Rs."
                      width="85px"
                      placeholder="amount"
                      error={amounts[index] < 1}
                      handleOnChange={(e) =>
                        handleInputAmount(index, e.target.value)
                      }
                    />:""}
                    <IconButton onClick={() => handleFileCrossButton(index)}>
                      <CancelIcon fontSize="small" sx={{ color: "#9A9A9A" }} />
                    </IconButton>
                  </div>
                </div>
              ))}
          </>
        )}
        {/* {finalUrls.length>0? <div className="totalCollectionAmountContainer">
          <span className="totalCollectionText">Collection Amount</span>
          <span className="totalCollectionAmount">Rs. {totalAmount}</span>
          </div>:""} */}
        {finalUrls.length > 0 ? (
          <div className="uploadFileDivider"></div>
        ) : (
          ""
        )}
      </div>
      <div>
        {allCashProofData.length > 0 || allFilesUrls.length > 0
          ? renderPreData()
          : ""}
      </div>
    </div>
  );
};
