import { toast } from "react-toastify";
import { centralCommonDataConstants } from "../../_constants";
import { centralCommonDataService } from "../../_services";
import { alertActions } from "../alert.actions";
import { jsonConstants } from "../../central/component/jsonConstant";

function getCentralCommonData() {
  return (dispatch) => {
    dispatch(request());

    centralCommonDataService.fetchCentralCommonData().then(
      (res) => {
        dispatch(success(res));
        //   history.push("/");
        // !!res?.description && toast.success(res.description)
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error.description)
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: centralCommonDataConstants.FETCH_COMMON_DATA,
    };
  }

  function success(commonData) {
    return { type: centralCommonDataConstants.CENTRAL_COMMON_DATA_FETCHED, commonData: JSON.parse(JSON.stringify(jsonConstants[0])) };
  }

  function failure(error) {
    return { type: centralCommonDataConstants.COMMON_DATA_FAILED, error };
  }
}

const updateCentralCommonData=(reportingData, disable)=>{
  const payload={reportingData, disable}
  return (dispatch)=>{
    dispatch({type:centralCommonDataConstants.UPDATE_COMMON_DATA, payload})
  }
}

const clearCentralCommonData=()=>{
  return (dispatch)=>{
    dispatch({type:centralCommonDataConstants.CLEAR_CENTRAL_COMMON_DATA})
  }
}


export const centralCommonDataActions = {
    getCentralCommonData,
    updateCentralCommonData,
    clearCentralCommonData
};
